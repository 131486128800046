<template>
    <div id="app" >
		
		<div class="header">
			<div class="logo"></div>
		</div>
        
		<div class="img-box">
			<van-swipe class="my-swipe" :autoplay="2000" indicator-color="white" :show-indicators="false" >
			  <van-swipe-item>
				  <img src="../assets/1.jpg">
			  </van-swipe-item>
			  <van-swipe-item>
				  <img src="../assets/2.jpg">
			  </van-swipe-item>
			  <van-swipe-item>
				  <img src="../assets/3.jpg">
			  </van-swipe-item>
			  <van-swipe-item>
				  <img src="../assets/4.jpg">
			  </van-swipe-item>
			</van-swipe>
		</div>
		
		<div class="cont-box">
			<div class="slogan">不停创造美味</div>
			<a class="download" @click="download()"></a>
		</div>
		
		<div>{{aaa}}</div>
		<div>{{bbb}}</div>
		
		<div class="mask" v-show="showDiv">
			<div class="pop-box">
				<a>请点击右上角</br>打开自带浏览器下载</a>
			</div>
		</div>
		
    </div>
</template>


<script>
	
import { Swipe, SwipeItem } from 'vant';
import { isWeixin } from "../rem/utils.js";

export default {
    name: 'mainPage',
    components: {

    },
    data() {
        return {
            curCode: '',
			aaa: '',
			bbb: '',
			isApple: true,
			showDiv: false,
			downadd: '',
			isWeixin: null,
			isIOS: null,
			
        }
    },
    mounted() {
		
		
		this.appDown();
		
		if(isWeixin()) {
			this.isWeixin = true;
		}
		else{
			this.isWeixin = false
		}
		
		if(this.isApple==true){
			this.isIOS = true
		}
		else{
			this.isIOS = false
		}
		
		
    },
    methods: {
		appDown() {
		   var u = navigator.userAgent;
		   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
		   var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
		   if(isiOS){
			   this.isApple = true
		   }else if(isAndroid){
			   this.isApple = false
		   }
		},
		download(){
			if(this.isWeixin==true){
				this.showDiv = true;
			}
			else{
				if(this.isApple==true){
					window.open("https://apps.apple.com/us/app/botinkit/id6471645587");
				}
				else{
					window.open("https://cos.botinkit.com/appPackage/release/btyc.apk");
				}
			}
		}
	  
	  
    }
}
</script>


<style scoped lang="scss">
	.header{
		width: 100%;
		height: 0.36rem;
		background: #000;
		padding-top: 0.10rem;
		padding-bottom: 0.05rem;
		.logo{
			background: url("../assets/logo.png") no-repeat;
			width: 0.658rem;
			height: 0.287rem;
			background-size: 100% 100%;
			margin: 0 auto 0 auto;
		}
	}
	
	.img-box{
		width: 100%;
		height: 4.2rem;
		background: red;
		.my-swipe .van-swipe-item {
		    font-size: 0.2rem;
		    text-align: center;
		    background-color: #39a9ed;
			width: 100%;
			height: 4.2rem;
			img{
				margin-top: -0.2rem;
				width: 100%;
				height: 4.2rem;
				object-fit: cover;
			}
			&:nth-child(4){
				img{
					margin-top: -0.36rem;
				}
			}
		}
	}
	
	.cont-box{
		position: relative;
		width: 100%;
		height: 0.82rem;
		&::before{
			content: '';
			background: url("../assets/mask.png") no-repeat;
			width: 3.75rem;
			height: 0.775rem;
			background-size: 100% 100%;
			position: absolute;
			top: -0.77rem;
			left: 50%;
			margin-left: -1.875rem;
		}
		.slogan{
			font-size: 0.26rem;
			text-align: center;
			letter-spacing: 0.02rem;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -0.5rem;
			font-weight: bold;
			// margin-top: -0.4rem;
		}
		.download{
			background: url("../assets/download.jpg") no-repeat;
			width: 2.46rem;
			height: 0.71rem;
			background-size: 100% 100%;
			display: block;
			position: absolute;
			top: 0.1rem;
			left: 50%;
			margin-left: -1.23rem;
		}
		
	}
	
	.mask{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.8);
		.pop-box{
			width: 2.4rem;
			height: 0.6rem;
			background: rgba(255,255,255,1);
			position: fixed;
			top: 50%;
			left: 50%;
			margin: -0.3rem 0 0 -1.2rem;
			border-radius: 0.2rem;
			vertical-align: middle;
			a{
				text-align: center;
				width: 100%;
				box-sizing: border-box;
				font-size: 0.16rem;
				padding: 0.06rem 0.4rem 0 0.4rem;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
	

</style>

<style>
#app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    margin: 0;
    padding: 0;
}

body, p, span {
    margin: 0;
    padding: 0;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

</style>
