import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './rem/rem.js'


import Vant from 'vant';
import 'vant/lib/index.css';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Vant);
Vue.use(ElementUI);

import axios from 'axios' //引入axios
Vue.prototype.$http = axios

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
