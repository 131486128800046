<template>
  <div id="app">
	<router-view></router-view>
  </div>
</template>

<script>
	

export default {
	name: 'App',
	components: {
		
	},
	data(){
		return{
			
		}
	},
	mounted(){
		
		window.onload = function() {
		document.addEventListener('touchstart', function(event) {
			if (event.touches.length > 1) {
				event.preventDefault()
			}
		})
		document.addEventListener('gesturestart', function(event) {
				event.preventDefault()
			})
		}
		
	},
	methods:{
		
	}
}
</script>


<style scoped lang="scss">
	
</style>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body,p,span{
	margin: 0;
	padding: 0;
}
html {
    font-size: 10px;  
}
body {
    font-size: 16px;
}
</style>
