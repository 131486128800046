import Vue from 'vue'
//对应你要跳转的组件

import Router from 'vue-router'

Vue.use(Router)

import mainPage from '../views/mainPage'

export default new Router({
	routes: [
		{
			path: '/',
			name: 'mainPage',
			component: mainPage,
		}
	]
})
